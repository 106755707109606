import { UAParser } from 'ua-parser-js';
import {
  getObjectFromLocalStorage,
  setObjectToLocalStorage,
} from '~/repositories/local-storage-repository';

const getUAData = () => {
  if (window === undefined) return;
  const parser = new UAParser(window.navigator.userAgent);
  return parser.getResult();
};

export const getDeviceProperty = (): string => {
  const properties: {
    key: string;
    value: string;
  }[] = [];

  const uaData = getUAData();

  const osName = uaData?.os?.name;
  if (osName) {
    properties.push({ key: 'os_name', value: osName });
  }

  const osVersion = uaData?.os?.version;
  if (osVersion) {
    properties.push({ key: 'os_version', value: osVersion });
  }

  const modelName = uaData?.device?.model;
  if (modelName) {
    properties.push({ key: 'hardware_model', value: modelName });
  }

  return properties.map(({ key, value }) => `${key}:${value}`).join(';');
};

const DEVICE_ID_KEY = '__tn_dvcid__';
const IS_REGISTERED_DEVICE_KEY_PREFIX = '__tn_is-registered-device';

export const getDeviceId = async () => {
  const deviceId = await getObjectFromLocalStorage<string>(DEVICE_ID_KEY);

  if (!deviceId) {
    const newDeviceId = crypto.randomUUID();
    await setObjectToLocalStorage(DEVICE_ID_KEY, newDeviceId);
    return newDeviceId;
  }

  return deviceId;
};

const getIsRegisteredDeviceKey = (userId: string) =>
  `${IS_REGISTERED_DEVICE_KEY_PREFIX}-${userId}`;

export const isRegisteredDevice = async (userId: string) => {
  const isRegisteredDevice = await getObjectFromLocalStorage<boolean>(
    getIsRegisteredDeviceKey(userId)
  );

  return isRegisteredDevice ?? false;
};

export const setIsRegisteredDevice = async (userId: string) => {
  await setObjectToLocalStorage(getIsRegisteredDeviceKey(userId), true);
};
